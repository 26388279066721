import { usePlatformStore } from '@/store/currentPlatformStore.js';
import { ROUTE_NAMES } from '../routeNames';

export async function withPlatformMiddleware(to, from, next) {
  const currentPlatformStore = usePlatformStore();

  const isPlatformUrlCorrect = await currentPlatformStore.fetchPlatform();

  if (!isPlatformUrlCorrect) {
    return next({
      name: ROUTE_NAMES.R_ROOT_ERROR_PLATFORM_NOT_FOUND,
    });
  }
  next();
}
