import axios from 'axios';
import { useRouter } from 'vue-router';
import { ROUTE_NAMES } from '../router/routeNames';
import { ElMessage } from 'element-plus';
export const API_BASE_URL = import.meta.env.VITE_APP_API_BASE_URL ?? '//localhost:3000/api';

const axiosConfig = {
  baseURL: API_BASE_URL,
  withCredentials: true,
};

const axiosInstance = axios.create(axiosConfig);
export const getRelevantSession = () => {
  return localStorage.getItem(`session`);
};
function requestInterceptor(config) {
  config.headers.Authorization = getRelevantSession();
  return config;
}
export const successResponseInterceptor = (response) => Promise.resolve(response.data ?? []);
export const errorResponseInterceptor = async (error) => {
  const router = useRouter();

  if (error?.response?.status === 413) {
    ElMessage({
      type: 'warning',
      message: 'Недостаточно свободного места на диске.',
      duration: 0,
      showClose: true,
    });
  }
  if (error?.response?.status === 401) {
    await router.push({
      name: ROUTE_NAMES.R_ROOT_LOGIN,
    });
  }
  throw error;
};

axiosInstance.interceptors.request.use((config) => requestInterceptor(config));
axiosInstance.interceptors.response.use(
  (response) => successResponseInterceptor(response),
  (error) => errorResponseInterceptor(error)
);

export const api = axiosInstance;
