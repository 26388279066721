import { getRelevantSession } from '@/api/axios.js';
import { ROUTE_NAMES } from '../routeNames';

export async function withoutAuthMiddleware(to, from, next) {
  const session = getRelevantSession();
  if (session) {
    return next({
      name: ROUTE_NAMES.R_ROOT_CONTROLLERS_MAIN,
    });
  }
  next();
}
