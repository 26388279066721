export const ROUTE_NAMES = {
  R_ROOT: 'Root',
  R_ROOT_ERROR_PLATFORM_NOT_FOUND: 'RootErrorPlatformNotFound',
  R_ROOT_FIRST_LOGIN: 'RootFirstLogin',
  R_ROOT_CONTROLLERS_MAIN: 'RootControllersMain',
  R_ROOT_PLATFORM_NEW: 'RootNewPlatform',
  R_ROOT_LOGIN: 'RootLogin',
  R_ROOT_LOGIN_BY_TOKEN: 'RootToken',
  R_ROOT_RESET_PASSWORD: 'RootResetPassword',
  R_ROOT_RESET_PASSWORD_REQUEST: 'RootResetPasswordRequest',

  R_MANAGER: 'Manager',
  R_MANAGER_MATERIALS: 'ManagerMaterials',
  R_MANAGER_MATERIALS_VIEW: 'ManagerMaterialsView',
  R_MANAGER_MATERIALS_UPSERT_TASK: 'ManagerMaterialsUpsertTask',
  R_MANAGER_MATERIALS_UPSERT_SCORM: 'ManagerMaterialsUpsertScorm',
  R_MANAGER_MATERIALS_UPSERT_COURSE: 'ManagerMaterialsUpsertCourse',
  R_MANAGER_CHECKUP: 'ManagerCheckup',
  R_MANAGER_SCHEDULE: 'ManagerSchedule',
  R_MANAGER_SCHEDULE_ROOT: 'ManagerScheduleRoot',
  R_MANAGER_SCHEDULE_UPSERT: 'ManagerScheduleUpsert',

  R_MANAGER_USERS: 'ManagerUsers',
  R_MANAGER_USERS_ACCOUNTS: 'ManagerAccounts',
  R_MANAGER_USERS_GROUPS: 'ManagerGroups',
  R_MANAGER_USERS_ORGSTRUCTURE: 'ManagerUsersOrgstructure',

  R_MANAGER_ASSIGNMENTS: 'ManagerAssignments',
  R_MANAGER_ASSIGNMENTS_UPSERT: 'ManagerAssignmentsUpsert',
  R_MANAGER_ASSIGNMENTS_VIEW: 'ManagerAssignmentsView',

  R_MANAGER_SETTINGS: 'ManagerSettings',

  R_MANAGER_ANALYTICS: 'ManagerAnalytics',
  R_MANAGER_ANALYTICS_DASHBOARD: 'ManagerAnalyticsDashboard',
  R_MANAGER_ANALYTICS_JOURNAL: 'ManagerAnalyticsJournal',
  R_MANAGER_ANALYTICS_STUDENTS: 'ManagerAnalyticsStudents',

  R_MANAGER_PROFILE: 'ManagerProfile',

  R_STUDENT: 'Student',
  R_STUDENT_MATERIALS_ALL: 'StudentAssignments',
  R_STUDENT_MATERIALS_ASSIGNED: 'StudentMaterialsAssigned',
  R_STUDENT_MATERIALS_STARTED: 'StudentMaterialsStarted',
  R_STUDENT_MATERIALS_COMPLETED: 'StudentMaterialsCompleted',
  R_STUDENT_MATERIALS_PLAY: 'StudentMaterialPlayer',
  R_STUDENT_PROFILE: 'StudentProfile',
  R_STUDENT_RESULTS: 'StudentResults',
  R_STUDENT_MATERIALS_COURSE_PLAY: 'StudentMaterialsCoursePlay',
  R_STUDENT_SCHEDULE: 'StudentSchedule',
  R_STUDENT_HOME: 'StudentHome',

  R_APP_USER_PROFILE: 'UserProfile',
  R_APP_USER_PROFILE_SETTINGS: 'UserProfileSettings',

  R_TEACHER: 'Teacher',
  R_TEACHER_MATERIALS: 'TeacherMaterials',
  R_TEACHER_ASSIGNMENTS: 'TeacherAssignments',
  R_TEACHER_ANALYTICS: 'TeacherAnalytics',
  R_TEACHER_PROFILE: 'TeacherProfile',
  R_TEACHER_CHECKUP: 'TeacherCheckup',
  R_TEACHER_SCHEDULE: 'TeacherSchedule',
};
