import { createApp } from 'vue';
import '@/assets/scss/app.scss';
import App from './App.vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import router from './router';
import { createPinia } from 'pinia';
import { Vue3Mq } from 'vue3-mq';
import ru from 'element-plus/dist/locale/ru';
import YouTube from 'vue3-youtube';
import JsonCSV from 'vue-json-csv';
import JsonExcel from 'vue-json-excel3';
import '@vuepic/vue-datepicker/dist/main.css';
import v3lc from 'vue3-line-clamp';

createApp(App)
  .component('downloadExcel', JsonExcel)
  .component('YouTube', YouTube)
  .component('DownloadCsv', JsonCSV)
  .use(router)
  .use(createPinia())
  .use(v3lc, {
    // options
  })
  .use(ElementPlus, {
    locale: ru,
  })
  .use(Vue3Mq, {
    breakpoints: {
      mobile: 0,
      tablet: 536,
      desktop: 900,
      lg: 1200,
    },
  })
  .mount('#app');
