import { useAuthStore } from '@/store/authStore.js';
import { ROUTE_NAMES } from '../routeNames';

export async function authByTokenMiddleware(to, from, next) {
  const token = to.query['t'];
  if (!token) {
    return next({
      name: ROUTE_NAMES.R_ROOT_LOGIN,
    });
  }

  const authStore = useAuthStore();
  await authStore.logout();
  await authStore.setToken(token);

  await next({
    name: ROUTE_NAMES.R_MANAGER,
  });
}
