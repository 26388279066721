import { useAuthStore } from '@/store/authStore.js';
import { ROUTE_NAMES } from '../routeNames';

export async function withAuthMiddleware(to, from, next) {
  const authStore = useAuthStore();

  const isCheckAuthSuccess = await authStore.checkAuth();

  if (!isCheckAuthSuccess) {
    await authStore.logout();
    return next({
      name: ROUTE_NAMES.R_ROOT_LOGIN,
    });
  }

  if (!authStore.isPasswordSet) {
    if (to.name !== ROUTE_NAMES.R_ROOT_FIRST_LOGIN) {
      return next({
        name: ROUTE_NAMES.R_ROOT_FIRST_LOGIN,
      });
    }
  }

  next();
}
