import { defineStore } from 'pinia';
import { AuthApi } from '@/api/modules/auth.js';

export const usePlatformStore = defineStore('platformStore', {
  state: () => ({
    platform: null,
  }),
  getters: {
    isDceIntegrationEnabled: (state) => state.platform?.integrationsMeta?.digitalCityEducation?.isEnabled,
    isDceImportEnabled: (state) => state.platform?.integrationsMeta?.digitalCityEducation?.isImportEnabled,
    isDceSyncEnabled: (state) => state.platform?.integrationsMeta?.digitalCityEducation?.isSyncEnabled,
  },
  actions: {
    async fetchPlatform() {
      const [err, platform] = await AuthApi.checkPlatform();
      if (err || !platform) {
        return false;
      }

      this.platform = {
        ...platform,
        guestGroupId: platform.guestGroupId ?? '',
        guestRoleId: platform.guestRoleId ?? '',
      };
      return true;
    },
  },
});
