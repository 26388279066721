import { defineStore } from 'pinia';
import { AuthApi } from '@/api/modules/auth.js';
import { getRelevantSession } from '@/api/axios.js';
import {
  PERMISSION_ACCESS_MANAGER,
  PERMISSION_ACCESS_STUDENT,
  PERMISSION_ACCESS_TEACHER,
} from '@/constants/permissions.js';

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    user: null,
    role: null,
    systemRole: null,
    permissions: [],
    token: null,
    backToken: null,
    updateLastOnlineInterval: null,
  }),

  getters: {
    isManager: (state) => state.permissions.includes(PERMISSION_ACCESS_MANAGER),
    isTeacher: (state) => state.permissions.includes(PERMISSION_ACCESS_TEACHER),
    isStudent: (state) => state.permissions.includes(PERMISSION_ACCESS_STUDENT),
    isPasswordSet: (state) => !!state.user.password,
    fullName: (state) => `${state.user.firstName} ${state.user.patroName ?? ''} ${state.user.lastName}`,
  },

  actions: {
    async setToken(token) {
      await localStorage.setItem('session', token);
      this.token = token;
    },

    async setBackToken(token) {
      await localStorage.setItem('backtoken', token);
      this.backToken = token;
    },

    async loginAsUser(userId) {
      const [err, result] = await AuthApi.loginAsUser(userId);
      if (err) {
        return false;
      }

      const { token } = result;
      await this.setBackToken(this.token);
      await this.logout();
      await this.setToken(token);
      return true;
    },

    async updateLastOnline() {
      const [err] = await AuthApi.updateLastOnline();
      return err;
    },

    async setupUpdateLastOnlineInterval() {
      if (this.updateLastOnlineInterval) {
        clearInterval(this.updateLastOnlineInterval);
      }
      this.updateLastOnlineInterval = setInterval(this.updateLastOnline, 60000);
    },

    async authorize(email, password) {
      const [err, result] = await AuthApi.login(email, password);
      if (err) {
        return false;
      }
      const { token } = result;
      await this.setToken(token);
      return true;
    },

    async checkAuth() {
      const token = getRelevantSession();
      if (!token) {
        return false;
      }

      const [err, result] = await AuthApi.whoami();

      if (err) {
        return false;
      }

      this.user = result;
      this.role = result.role;
      this.systemRole = result.systemRole;
      this.permissions = result.role.permissions;
      this.token = token;
      this.updateLastOnline();
      this.setupUpdateLastOnlineInterval();
      return true;
    },

    async logout() {
      await this.setToken('');
      this.token = null;
    },
  },
});
