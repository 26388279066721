import { api } from '@/api/axios.js';

export const AuthApi = {
  async login(email, password) {
    try {
      const result = await api.post('/auth', {
        email,
        password,
      });

      return [null, result];
    } catch (e) {
      return [e, null];
    }
  },

  async checkPlatform() {
    try {
      const result = await api.get('/auth/platform');
      return [null, result];
    } catch (e) {
      return [e, null];
    }
  },

  async whoami() {
    try {
      const result = await api.get('/auth/whoami');
      return [null, result];
    } catch (e) {
      return [e, null];
    }
  },

  async setPassword(newPassword) {
    try {
      const result = await api.post('/auth/setpassword', { password: newPassword });
      return [null, result];
    } catch (err) {
      return [err, null];
    }
  },

  async firstLogin({ email, password }) {
    try {
      const result = await api.post('/auth/first-login', { email, password });
      return [null, result];
    } catch (err) {
      return [err, null];
    }
  },

  async resetPassword({ email }) {
    try {
      const result = await api.post('/auth/reset-password/make', {
        email,
      });
      return [null, result];
    } catch (error) {
      return [error, null];
    }
  },

  async resetPasswordCheck(token) {
    try {
      const result = await api.post('/auth/reset-password/check', {
        token,
      });
      return [null, result];
    } catch (error) {
      return [error, null];
    }
  },

  async updateLastOnline() {
    try {
      await api.get('/auth/online');
      return [null];
    } catch (error) {
      return [error];
    }
  },

  async loginAsUser(userId) {
    try {
      const result = await api.get(`/auth/login-as-user/${userId}`);
      return [null, result];
    } catch (error) {
      return [error, null];
    }
  },
};
