import { useAuthStore } from '../../store/authStore';
import { ROUTE_NAMES } from '../routeNames';

export function withRolePermissions(roles = ['teacher', 'student', 'manager']) {
  const handle = async (to, from, next) => {
    const authStore = useAuthStore();

    if (authStore.isTeacher && !roles.includes('teacher')) {
      return next({
        name: ROUTE_NAMES.R_ROOT_CONTROLLERS_MAIN,
      });
    }

    if (authStore.isStudent && !roles.includes('student')) {
      return next({
        name: ROUTE_NAMES.R_ROOT_CONTROLLERS_MAIN,
      });
    }

    if (authStore.isManager && !roles.includes('manager')) {
      return next({
        name: ROUTE_NAMES.R_ROOT_CONTROLLERS_MAIN,
      });
    }

    next();
  };

  return handle;
}
